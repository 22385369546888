






import MissionStatus from "@/components/GroupTeams/Common/Games/GameCardParts/MissionStatus.vue"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"

import Vue from "vue"

export default Vue.extend({
  name: "MobileWelcome",
  components: {
    MissionStatus,
    ResizableText
  },
  props: {
    text: {
      type: [String, Boolean],
      default: "Waiting to play..."
    }
  }
})
